<template>
  <lab-task>
    <lab-gas-laws-part3 @allGuidanceComplete="submitResponse" />
  </lab-task>
</template>

<script>
import {CompletableSimMixin} from '../../mixins/completable-sim';
import {defineComponent} from '@vue/composition-api';
import LabGasLawsPart3 from '../simulations/LabGasLawsPart3.vue';
import LabTask from './labs/LabTask';

export default defineComponent({
  name: 'GasLawsPart3',
  components: {LabGasLawsPart3, LabTask},
  mixins: [CompletableSimMixin()],
});
</script>
